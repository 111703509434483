import ApplicationController from "@script/controllers/application_controller";
import ajax from '@script/utils/ajax';
import replace from '@script/utils/replace';

export default class TicketsController extends ApplicationController {
  static targets = ['termsModal'];

  connect(){
    super.connect();
  }

  showTerms(e){
    e.preventDefault();
    this.termsModalTarget.modalController.open();
  }

// Ниже - функции для управления блоком "с 15 марта 2025 билеты по паспорту", который перекрывает виджет
  checksCheckboxes(){
    let allchecked = true;
    Array.from(document.getElementById("agreements").elements).filter((el) => el.type == "checkbox").forEach((checkbox) => !checkbox.checked && (allchecked = false));
    return allchecked;
  }
  submitAavail(){
    let button = document.getElementById("agreements").querySelector('input[type="submit"]');
    if(this.checksCheckboxes()){
      button.disabled = false;
      button.style.filter='invert(1)';
    }else{
      button.disabled = true;
      button.style.filter='';
    }
  }
  showWidget(e){
    e.preventDefault();
    if(this.checksCheckboxes()){
      document.querySelector('.intickets-frame-container').style.pointerEvents='unset';
      document.querySelector('.intickets-frame-container').style.opacity='unset';
      document.querySelector('.intickets-frame-container-agreement').style.display='none';
    };
  }

}
